/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var globalYear;
var globalOldEvent = false;

(function($) {

  var _map;
  var _marker;
  var _markerClusterer;
  var _selectedMarker;
  var _mediaList = [];
  var _mapStyleUser = 'worldorienteeringday';
  var _mapStyleId = 'cik6skv8r00f8btj7peaox8iy';
  var _mapAccessToken = 'pk.eyJ1Ijoid29ybGRvcmllbnRlZXJpbmdkYXkiLCJhIjoiY2lrNnJycHk4MDAxZXZza291ajlzazFnNiJ9.YZeOQaY05NZ1Hr6yu7eqkw';
  var _mapStyle = 'mapbox://styles/' + _mapStyleUser + '/' + _mapStyleId;
  var _markersLayer;

  // Normalizes the coords that tiles repeat across the x axis (horizontally)
  // like the standard Google map tiles.
  var _getNormalizedCoord = function (coord, zoom) {
    var y = coord.y;
    var x = coord.x;

    // tile range in one direction range is dependent on zoom level
    // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
    var tileRange = 1 << zoom;

    // don't repeat across y-axis (vertically)
    if (y < 0 || y >= tileRange) {
      return null;
    }

    // repeat across x-axis
    if (x < 0 || x >= tileRange) {
      x = (x % tileRange + tileRange) % tileRange;
    }

    return {x: x, y: y};
  };

  var _mapboxMapType = function(){
    return new google.maps.ImageMapType(
      {
        getTileUrl: function(coord, zoom) {
          zoom = zoom - 1;
          var normalizedCoord = _getNormalizedCoord(coord, zoom);
          if (!normalizedCoord) {
            return null;
          }
          var url = 'https://api.mapbox.com/styles/v1/' + _mapStyleUser + '/' + _mapStyleId + '/tiles' +
                    '/' + zoom + '/' + normalizedCoord.x + '/' +
                    normalizedCoord.y + '@2x?access_token=' + _mapAccessToken;
          return url;
        },
        tileSize: new google.maps.Size(512, 512),
        radius: 1738000,
        maxZoom: 20,
        minZoom: 0,
        name: 'mapbox'
      }
    );
  };

  var _showLocationDetails = function(data, position){
    var $locationDetails = $('#location-details');

    $locationDetails.find('h3.name').text(data.name);
    $locationDetails.find('.contacts, .attachments, .stats-container').hide();
    $locationDetails.find('.contact-name, .contact-phone, .contact-email, .attachments-list, .years').html('');

    if(data.user){
      $locationDetails.find('.contact-name').text(data.user.name);
      $locationDetails.find('.contact-phone').text(data.user.phone);
      $locationDetails.find('.contact-email').html('<a href="mailto:' + data.user.email + '">' + data.user.email + '</a>');
      $locationDetails.find('.contacts').show();
    }

    if(data.years) {
      var years = [];
      $(data.years).each(function(){
        var selected = (this.year == data.year ? ' class="selected"' : '');
        years.push('<a href="javascript:void(0)" data-id="' + this.id + '"' + selected + '>' + this.year + '</a>');
      });
      $locationDetails.find('.years').html(years.join(''));
    }

    if(data.media && (media = $.parseJSON(data.media))){
      var items = [];
      $(media).each(function(idx){
        items.push('<a href="' + this + '" target="_blank">' + (idx + 1) + '</a>');
      });
      $locationDetails.find('.attachments-list').html(items.join(', '));
      $locationDetails.find('.attachments').show();
    }

    if(data.stats && data.stats.world){
      var stats = data.stats;
      var percent = parseInt(stats.world) / 100;

      $locationDetails.find('.stats-location span').text(data.name);
      $locationDetails.find('.stats-location strong').text(stats.location);
      $locationDetails.find('.stats-country span').text(data.country);
      $locationDetails.find('.stats-country strong').text(stats.country);
      $locationDetails.find('.stats-world strong').text(stats.world);
      $locationDetails.find('.progress-world').css('width', '100%');
      $locationDetails.find('.progress-country').css('width', Math.round(stats.country / percent) + '%');
      $locationDetails.find('.progress-location').css('width', Math.round(stats.location / percent) + '%');
      $locationDetails.find('.stats-container').show();
    }

    $locationDetails.fadeIn();

    _map.panTo(position);
  };

  var _getTotalByGroup = function(group){
    var total = 0;
    $.map(group, function(val, idx){
      var value = parseInt($(val).val());
      if(value) {
        total += value;
      }
    });
    return total;
  };

  var _removeSelectedMarker = function(){
    if(_selectedMarker){
      _selectedMarker.setIcon(_selectedMarker.defaultIcon);
      _selectedMarker = null;
    }
  };

  var _addSelectedMarker = function(marker){
    _removeSelectedMarker();
    _selectedMarker = marker;
    _selectedMarker.setIcon('/wp-content/themes/wod/assets/images/icon_active.png');
  };

  var _getLocation = function(marker){
    _addSelectedMarker(marker);
    _getLocationData(marker.id, marker.getPosition());
  };

  var _getLocationData = function(id, position) {
    $.getJSON( "/index.php/get-location?id=" + id, function(response) {
      _showLocationDetails(response, position);
    });
  };

  var _loadSchools = function(){
    var clusterStyles = [
      {
        textColor: 'black',
        url: '/wp-content/themes/wod/assets/images/m5.png',
        textSize: 16,
        height: 50,
        width: 50
      },
      {
        textColor: 'black',
        url: '/wp-content/themes/wod/assets/images/m4.png',
        textSize: 16,
        height: 40,
        width: 40
      },
      {
        textColor: 'black',
        url: '/wp-content/themes/wod/assets/images/m3.png',
        textSize: 15,
        height: 30,
        width: 30
      },
      {
        textColor: 'black',
        url: '/wp-content/themes/wod/assets/images/m2.png',
        textSize: 14,
        height: 20,
        width: 20
      },
      {
        textColor: 'black',
        url: '/wp-content/themes/wod/assets/images/m1.png',
        textSize: 12,
        height: 10,
        width: 10
      }
    ];

    var bounds = new google.maps.LatLngBounds();
    if(!_markerClusterer) {
      _markerClusterer = new MarkerClusterer(_map);
      _markerClusterer.setStyles(clusterStyles);

      google.maps.event.addListener(_map.data, 'addfeature', function (e) {
        if (e.feature.getGeometry().getType() === 'Point') {
          var icon = '/wp-content/themes/wod/assets/images/icon_' + e.feature.getProperty('icon') + '.png';
          var marker = new google.maps.Marker({
            position: e.feature.getGeometry().get(),
            id: e.feature.getProperty('id'),
            defaultIcon: icon,
            icon: icon,
            map: _map
          });
          google.maps.event.addListener(marker, 'click', function(){
            _getLocation(marker);
          });
          _markerClusterer.addMarker(marker);
          bounds.extend(e.feature.getGeometry().get());
          //_map.fitBounds(bounds);
          _map.setCenter(e.feature.getGeometry().get());
        }
      });
    }

    _markersLayer = _map.data.loadGeoJson("/index.php/get-locations?year=" + globalYear);
    _map.data.setMap(null);
  };

  var _initMap = function(){
    _map = new google.maps.Map(document.getElementById('main-map'),
      {
        streetViewControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        center: {lat: 10, lng: 26},
        zoom: 0,
        minZoom: 2
      }
    );
    _map.mapTypes.set('mapbox', _mapboxMapType());
    _map.setMapTypeId('mapbox');

    $locationDetails = $('#location-details');
    $locationDetails.find('.close').click(function(){
      $locationDetails.fadeOut();
      _removeSelectedMarker();
    });

    var to = setInterval(function(){
      if(_map.getZoom()){
        clearInterval(to);
        _loadSchools();
      }
    }, 100);
  };

  var _clearMap = function() {
    _markerClusterer.clearMarkers();
    //_markerClusterer.setMap(null);
  };

  var _setLocationFields = function(data){
    var country = $.map(data.address_components, function(val, idx){
      if($.inArray('country', val.types) > -1){
        return val.long_name;
      }
    });

    var $locationForm = $('#wod-location-form');
    $locationForm.find('input[name="location[lat]"]').val(data.geometry.location.lat());
    $locationForm.find('input[name="location[lng]"]').val(data.geometry.location.lng());
    $locationForm.find('input[name="location[address]"]').val(data.formatted_address);
    $locationForm.find('input[name="location[country]"]').val(country);
    //console.log(data.geometry.location.lat(), data.geometry.location.lng(), country);
  };

  var _clearLocationFields = function(){
    var $locationForm = $('#wod-location-form');
    $locationForm.find('input[name="location[lat]"]').val('');
    $locationForm.find('input[name="location[lng]"]').val('');
    $locationForm.find('input[name="location[country]"]').val('');
  };

  var _geocodePosition = function (pos){
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: pos },
      function(results, status){
        if (status === google.maps.GeocoderStatus.OK) {
          var result = results[0];
          _setLocationFields(result);
        }
      }
    );
  };

  var _setMarker = function(position){
    _marker = new google.maps.Marker({
      map: _map,
      position: position,
      draggable: !$('#location-address').attr('disabled')
    });
    google.maps.event.addListener(_marker, 'dragend', function(){
      _clearLocationFields();
      _geocodePosition(_marker.getPosition());
    });
  };

  var _setLocation = function(place){
    _clearLocationFields();
    var position = place.geometry.location;
    if(!_marker){
      _setMarker(position);
    } else {
      _marker.setPosition(position);
    }
    _map.setCenter(position);
    _map.setZoom(15);
    _setLocationFields(place);
  };

  var _initAutocomplete = function(){
    var autocomplete = new google.maps.places.Autocomplete(document.getElementById('location-address'), {types: ['geocode']});
    autocomplete.addListener('place_changed', function() {
      _clearLocationFields();

      var place = autocomplete.getPlace();
      if (place.geometry) {
        _setLocation(place);
      }
    });
  };

  var _initLocationMap = function(){
    var $locationForm = $('#wod-location-form');
    var defaultPosition = {lat: 10, lng: 26};
    var defaultZoom = 0;
    var id = $locationForm.find('input[name="location[id]"]').val();

    if(id !== ''){
      defaultPosition.lat = parseFloat($locationForm.find('input[name="location[lat]"]').val());
      defaultPosition.lng = parseFloat($locationForm.find('input[name="location[lng]"]').val());
      defaultZoom = 10;
    }

    _map = new google.maps.Map(document.getElementById('location-map'),
      {
        streetViewControl: false,
        mapTypeControl: false,
        center: defaultPosition,
        zoom: defaultZoom
      }
    );
    _map.mapTypes.set('mapbox', _mapboxMapType());
    _map.setMapTypeId('mapbox');

    if(id !== ''){
      _setMarker(defaultPosition);
    }

    _initAutocomplete();
  };

  var _validateLocationForm = function($locationForm){
    var total = parseInt($locationForm.find('input[name="location[total_participants]"]').val());
    if(total){
      var totalAge = _getTotalByGroup($locationForm.find('input.total_age'));
      if(totalAge !== total){
        alert("Sum of participants per age does not equal to total number of participants.");
        return false;
      }

      var totalGender = _getTotalByGroup($locationForm.find('input.total_gender'));
      if(totalGender !== total){
        alert('Sum of participants per gender does not equal to total number of participants.');
        return false;
      }
    }
    return true;
  };

  var _getMediaName = function(filename){
    var urlParts = filename.split('/');
    var filenameParts = urlParts[urlParts.length - 1].split('_');
    filenameParts.splice(0, 1);
    return filenameParts.join('_');
  };

  var _renderMediaList = function(){
    var items = [];
    var $locationForm = $('#wod-location-form');
    var $mediaList = $locationForm.find('.media-list');
    $(_mediaList).each(function(){
      items.push('<li><a href="' + this + '" target="_blank">' + _getMediaName(this) + '</a> <a href="javascript:void(0)" class="remove-media"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></a></li>');
    });
    $mediaList.html(items.join(''));
  };

  var _initNav = function(){
    var $navbar = $('#navbar');
    $navbar.find('#menu-header li:not(.menu-item-type-custom) a').each(function(){
      var $this = $(this);
      var href = $this.attr('href').split('/');
      $this.attr('href', '#' + href[href.length - 2]);
    });
    //$navbar.show();

    var $posts = $('.posts');
    $posts.find(' a').each(function(){
      var $this = $(this);
      var href = $this.attr('name');
      if(href) {
        href = href.split('/');
        $this.attr('name', href[href.length - 2]);
      }
    });
  };

  var _bindEvents = function() {
    var $years = $('#location-details .years');
    $years.on('click', 'a', function(){
      var $this = $(this);
      _getLocationData($this.attr('data-id'), _selectedMarker.getPosition());
    });

    var $mapYears = $('#years');
    $mapYears.on('click', 'a', function(){
      var $this = $(this);

      $mapYears.find('a').removeClass('selected');
      $this.addClass('selected');
      globalYear = $this.attr('data-year');

      _clearMap();
      _loadSchools();
    });
  };

  function _setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function _getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  var _initBlock2 = function() {
    var $container = $('#block_2_container');
    var movie = $('#block_2_movie').val();
    var slideShowDelay = parseInt($('#block_2_slideshow_delay').val());

    if(images = JSON.parse($('#block_2_images').val())) {
      var slidesNav = [];
      var slides = [];
      $(images).each(function(idx){
        slidesNav.push('<li data-target="#images2" data-slide-to="' + idx + '"' + (!idx ? ' class="active"' : '') + '></li>');
        var img = '<img src="' + this.image + '">';
        var slide = this.url ? '<a href="' + this.url + '">' + img + '</a>' : img;
        slides.push('<div class="item' + (!idx ? ' active' : '') + '">' + slide + '</div>');
      });

      var carousel = '\
        <div id="images2" class="carousel slide" data-ride="carousel" data-interval="' + (slideShowDelay ? slideShowDelay : 3000 ) + '">\
          <ol class="carousel-indicators">' + slidesNav.join('') + '</ol>\
          <div class="carousel-inner" role="listbox">' + slides.join('') + '</div>\
          <a class="left carousel-control" href="#images2" role="button" data-slide="prev">\
            <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>\
            <span class="sr-only">Previous</span>\
          </a>\
          <a class="right carousel-control" href="#images2" role="button" data-slide="next">\
            <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>\
            <span class="sr-only">Next</span>\
          </a>\
        </div>';
      if(movie != '') {
        var autoplay = _getCookie('intro') ? '' : ' autoplay ';
        var player = (movie.toLowerCase().indexOf('youtube.com') > -1 || movie.toLowerCase().indexOf('youtu.be') > -1 ) ?
                     '<iframe width="100%" height="248" src="' + movie + '" frameborder="0" allowfullscreen></iframe>' :
                     '<video width="100%"' + autoplay + 'controls><source src="' + movie + '" type="video/mp4"></video>';
        //
        var video = '<div class="movie">' + player + '</div>';
        $container.html('<div class="images-movie">' + video + carousel + '</div>');
        _setCookie('intro', '1', 7);
      } else {
        $container.html('<div class="images">' + carousel + '</div>');
      }
    }
  };

  var _init = function(){
    _initMap();
    _initNav();
    _initBlock2();
    _bindEvents();
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var WOD = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        _init();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'auth': {
      init: function() {
        var $userForm = $('#wod-user-form');
        $userForm.find('.submit').click(function(){
          $userForm.submit();
        });

        var $loginForm = $('#wod-login-form');
        $loginForm.find('.submit').click(function(){
          $loginForm.submit();
        });
      }
    },
    'account': {
      init: function() {
        var $userForm = $('#wod-user-form');
        $userForm.find('.submit').click(function(){
          $userForm.submit();
        });
      }
    },
    'register': {
      init: function() {
        var $registered = $('#registered a');
        var $registeredId = $('#wod-location-form input[name="location[id]"]');
        if($registered.length && $registeredId.val() == '' && document.location.href.indexOf('operation=new') == -1) {
          document.location = '/index.php/register?id=' + $registered.eq(0).attr('data-id');
          return;
        };

        var $locationForm = $('#wod-location-form');
        $locationForm.find('.submit').click(function(){
          if(_validateLocationForm($locationForm)){
            $locationForm.submit();
          }
        });

        $locationForm.find('.copy').click(function(){
          $locationForm.find('input[name="operation"]').val('copy_location');
          $locationForm.submit();
        });

        $locationForm.find('.delete').click(function(){
          if(confirm('Are you sure?')){
            $locationForm.find('input[name="operation"]').val('delete_location');
            $locationForm.submit();
          }
        });

        $locationForm.find('.upload').click(function(e){
          $locationForm.find('input[name="media[]"]').trigger('click');
          e.preventDefault();
        });

        $locationForm.find('input[name="media[]"]').on('change', function(){
          var media = [];
          $(this.files).each(function(){
            media.push(this.name);
          });
          $locationForm.find('.media-to-upload').html(media.join(', '));
        });

        var media = $locationForm.find('input[name="location[media]"]').val();
        if(media !== ''){
          var mediaJson = $.parseJSON(media);
          if(mediaJson){
            _mediaList = mediaJson;
            _renderMediaList();
          }
        }

        $locationForm.find('.media-list').on('click', 'li .remove-media', function(){
          if(_mediaList){
            _mediaList.splice($(this).parent().index(), 1);
            _renderMediaList();
            $locationForm.find('input[name="location[media]"]').val(JSON.stringify(_mediaList));
          }
        });

        _initLocationMap();
      }
    },
    'forgot_password': {
      init: function() {
        var $forgotPasswordForm = $('#wod-forgot-password-form');
        $forgotPasswordForm.find('.submit').click(function(){
          $forgotPasswordForm.submit();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = WOD;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
